<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            LOG IN
          </h2>
        </b-link>

        <b-card-title class="mb-1" />
        <b-card-text class="mb-2" />

        <!-- form -->
        <validation-observer ref="loginValidation">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="ID"
            >
              <validation-provider
                #default="{ errors }"
                name="ID"
                rules="required"
              >
                <b-form-input
                  id="login-email"
                  v-model="userid"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="ID"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Password</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    ref="loginPassword"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              @click="validationForm()"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'signup1'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text>
        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'forgot'}">
            <span>Help</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
      <b-modal
        id="modal-sm"
        ref="my-modal-password"
        small
        title="비밀번호 변경"
        centered
        button-size="sm"
        size="sm"
        style="max-width: 400px !important;"
        ok-title="저장"
        @shown="setFocus"
        @ok="validationPassword('resetPassword')"
      >
        <b-form-group
          class="font-small-3 text-info"
          label="비밀번호 변경"
          label-for="event-period"
          label-cols-md="20"
        >
          <b-form-input
            id="input-password"
            ref="inputPassword"
            v-model="resetPasswordValue"
            class="font-small-3 text-info"
            size="sm"
            placeholder="Enter password"
            style="color:rgb(229,166,48); width: 15rem"
          />
        </b-form-group>
        <b-form-group
          class="font-small-3 text-info"
          label="비밀번호 확인"
          label-for="event-period"
          label-cols-md="20"
        >
          <b-form-input
            id="input-password-check"
            v-model="resetPasswordCheckValue"
            class="font-small-3 text-info"
            size="sm"
            placeholder="Enter password"
            style="color:rgb(229,166,48); width: 15rem"
          />
        </b-form-group>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BModal,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

import { CLEAR_ALL, LOGIN, LOGOUT } from '@/store/auth/action'
import { SIGNIN_LOGS } from '@/store/logs/action'
import { FETCH_SET_IP_BLOCK } from '@/store/settings/action'
import { UPDATE_USERS_PASSWORD, UPDATE_USERS_STATUS, INSERT_SET_IP_BLOCK} from '@/store/auth/mutation'
import router from '@/router'

import { createNamespacedHelpers } from 'vuex'
import axios from 'axios'

const settingsStore = createNamespacedHelpers('settingsStore')
const authStore = createNamespacedHelpers('authStore')
const logStore = createNamespacedHelpers('logStore')

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // userid: 'perdonis',
      // password: 'vpfmthsK7*',
      userid: 'smk12',
      password: '1234',
      status: '',
      // validation rules
      required,
      // client info
      site: '',
      flagUrl: '',
      ip: '',
      browser: '',
      browserVer: '',
      os: '',
      osVer: '',
      platform: '',
      networkCo: '',
      networkType: '',
      asn: '',
      route: '',
      country: '',
      city: '',
      latitude: '',
      longitude: '',
      countryCity: '',
      resetPasswordValue: '',
      resetPasswordCheckValue: '',
      subTitle: '',
      loginCount: 0,
      loginCountMsg: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    ...settingsStore.mapGetters({
      fetchSetIpBlock: 'fetchSetIpBlock',
    }),
    settingsStore() {
      return this.$store.state.settingsStore.fetchSetIpBlock
    },
  },
  mounted() {
    this.clearAll()
    this.getIpClient()
  },
  methods: {
    ...authStore.mapActions({
      login: LOGIN,
      logout: LOGOUT,
      clearAll: CLEAR_ALL,
      $updateUsersPassword: UPDATE_USERS_PASSWORD,
      $updateUsersStatus: UPDATE_USERS_STATUS,
      $insertSetIpBlock: INSERT_SET_IP_BLOCK,
    }),
    ...logStore.mapActions({
      $SigninLogs: SIGNIN_LOGS,
    }),
    ...settingsStore.mapActions({
      $fetchSetIpBlock: FETCH_SET_IP_BLOCK,
    }),
    async fetchData() {
      await this.$fetchSetIpBlock({
        site: this.$site,
      }).then(res => {
        for (const i of this.fetchSetIpBlock) {
          // admin ip에 등록되있지 않으면 404로 방출
          if (this.ip === i.ip) {
            router.replace('/error-404')
          }
        }
        return true
      })
    },
    showToast(title, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          variant: 'success',
        },
      },
      {
        position,
      })
    },
    async validationForm() {
      const success = await this.$refs.loginValidation.validate()
      if (success) {
        try {
          await this.login({
            site: this.$site,
            userid: this.userid,
            password: this.password,
          })
            .then(response => {

              const { status } = this.$store.getters['authStore/user']
              switch (status) {
                case 'changePassword':
                  this.$refs['my-modal-password'].show()
                  break
                case 'active':
                  this.loginCount = 0
                  this.showToast('Login Success', 'top-center')
                  this.$router.replace('/')
                  break
                case 'apply':
                case 'await':
                  this.loginCount = 0
                  this.popUp({
                    title: '현재 가입 승인 대기중입니다.',
                    showCancelButton: false,
                    routePath: false,
                  })
                  break
                case 'additional':
                  this.loginCount = 0
                  this.popUp({
                    title: '관리자 승인이 되었습니다. \n 추가정보 입력이 필요합니다.',
                    text: '추가정보 입력화면으로 전환됩니다.',
                    showCancelButton: true,
                    routePath: 'signup2',
                  })
                  // this.popUp({
                  //   title : '관리자 최종 승인 검토중입니다. \n 최종 승인 후에 로그인이 가능합니다.',
                  //   text: '',
                  //   showCancelButton : false,
                  //   routePath : '',
                  // });
                  break
                case 'sleep':
                  this.loginCount = 0
                  this.popUp({
                    title: '이 계정은 휴면상태 입니다. \n 관리자에게 문의바랍니다.',
                    text: '',
                    showCancelButton: false,
                    routePath: '',
                  })
                  break
                case 'block':
                  this.loginCount = 0
                  this.popUp({
                    title: '등록되지 않은 계정이거나, 비밀번호가 다릅니다.',
                    text: '',
                    showCancelButton: false,
                    routePath: '',
                  })
                  break
              }
            })
        } catch (e) {
          this.loginCount++
          this.loginCountMsg = `로그인 실패 ${this.loginCount}회 / 10`
          this.showToast(this.loginCountMsg)
          if (this.loginCount === 10) {
            await this.$updateUsersStatus({
              site: this.$site,
              userid: this.userid,
              status: 'block',
            })
          }
          if (this.loginCount === 20) {
            await this.$insertSetIpBlock({
              site: this.$site,
              ip: this.ip,
              type: 'auto',
              memo: '비밀번호 20회 잠김',
              enrollId: 'AI',
            })
            await this.fetchData()
          }
          console.log(e)
        } finally {
          await this.$SigninLogs({
            site: this.$site,
            userid: this.userid,
            ip: this.ip,
            browser: this.browser,
            browserVer: this.browserVer,
            os: this.os,
            osVer: this.osVer,
            platform: this.platform,
            networkCo: this.networkCo,
            networkType: this.networkType,
            asn: this.asn,
            route: this.route,
            flag: this.flagUrl,
            country: this.country,
            city: this.city,
            latitude: this.latitude,
            longitude: this.longitude,
          })
        }
      }
    },
    async getIpClient() {
      try {
        const response = await axios.get('https://api.ipregistry.co?key=cwmdadv8au7nkq34')

        this.ip = response.data.ip
        this.countryCity = `${response.data.location.country.name}, ${response.data.location.city}`
        this.flagUrl = response.data.location.country.flag.noto
        this.browser = response.data.user_agent.name
        this.browserVer = response.data.user_agent.version
        this.os = response.data.user_agent.os.name
        this.osVer = response.data.user_agent.os.version
        this.platform = response.data.user_agent.device.type
        this.networkCo = response.data.company.name
        this.networkType = response.data.company.type
        this.asn = String(response.data.connection.asn)
        this.route = response.data.connection.route
        this.country = response.data.location.country.name
        this.city = response.data.location.city
        this.latitude = String(response.data.location.latitude)
        this.longitude = String(response.data.location.longitude)

        localStorage.setItem('ip', this.ip)
        localStorage.setItem('platform', this.platform)
        localStorage.setItem('latitude', this.latitude)
        localStorage.setItem('longitude', this.longitude)
        // console.log(response)

        await this.fetchData()
      } catch (error) {
        console.error(error)
      }
    },
    popUp(popUpData) {
      Swal.fire({
        title: popUpData.title,
        text: popUpData.text,
        icon: 'warning',
        showCancelButton: popUpData.showCancelButton,
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        background: 'rgb(3,11,37)',
        customClass: {
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed && popUpData.routePath != '') {
          this.$router.push({
            name: popUpData.routePath,
          })
        }
      })
    },
    setFocus() {
      this.$refs.inputPassword.focus()
    },
    validationPassword(target) {
      if (target === 'resetPassword') {
        if (this.resetPasswordValue !== this.resetPasswordCheckValue) {
          this.showToast('입력하신 비밀번호가 일치하지 않습니다')
          this.resetPasswordValue = ''
          this.resetPasswordCheckValue = ''
        } else if (this.resetPasswordValue === '1234') {
          this.showToast('다른 패스워드를 사용해주세요')
          this.resetPasswordValue = ''
          this.resetPasswordCheckValue = ''
        } else {
          this.submit(target)
        }
      }
    },
    async submit(target) {
      if (target === 'resetPassword') {
        this.subTitle = '비밀번호를 변경하시겠습니까?'
      }
      await Swal.fire({
        title: this.subTitle,
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    confirmYes(target) {
      const mySite = this.$site
      const myId = this.userid
      try {
        if (target === 'resetPassword') {
          this.$updateUsersPassword({
            site: mySite,
            userid: myId,
            password: this.resetPasswordValue,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.password = ''
          this.$refs.loginPassword.focus()
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
